<template>
	<main class="page-main">
		<section class="hero-section" data-section="intro">
			<div class="content-wrap">
				<div class="text-box">
					<h1 class="hero-title" data-aos><span class="line"><span class="text">If you’re going through</span></span>
						<br><span class="line"><span class="text">Hell, keep going!</span></span></h1>
					<p data-aos="fade-up" data-aos-delay="1700">The first Solana's Meme Token with NFT project providing
						unique experiences to evil holders. 3.5%
						fee in the casino which will be shared amongst NFT holders.</p>
					<a href="#" class="button style-3" data-aos="fade-up" data-aos-delay="2000">
						<span class="inner">Let's Buy <span class="highlight underline">#HELL</span></span>
					</a>
				</div>
				<div class="actions">
					<div class="scroll-down-box">
						<p>Scroll down</p>
						<a @click.prevent="scrollToSection" href="#" class="scroll-down" data-target-section="features"><i
								class="fa-solid fa-angle-down"></i></a>
					</div>
				</div>
			</div>
		</section>
		<section class="content-section features" data-section="features">
			<header class="section-header">
				<div class="content-wrap">
					<h3 class="section-label-title style-1" data-aos="fade-up">Let's f*cking moon</h3>
					<h2 class="section-title" data-aos="fade-up">We are all just<br>Slaves in the Hell</h2>
					<p data-aos="fade-up">The first Solana's Meme Token with NFT project providing unique experiences to
						evil holders. 3.5%
						fee in the casino which will be shared amongst NFT holders.</p>
				</div>
			</header>
			<article>
				<div class="content-wrap">
					<div class="feature-boxes-container">
						<div class="feature-box" data-aos="fade-up">
							<figure class="image">
								<img src="@/assets/img/feature-box/01.png">
							</figure>
							<h4 class="feature-title">Solana<br>Ecosystem</h4>
						</div>
						<div class="feature-box" data-aos="fade-up" data-aos-delay="250">
							<figure class="image">
								<img src="@/assets/img/feature-box/02.png">
							</figure>
							<h4 class="feature-title">Reward<br>Staking Tiers</h4>
						</div>
						<div class="feature-box" data-aos="fade-up" data-aos-delay="500">
							<figure class="image">
								<img src="@/assets/img/feature-box/03.png">
							</figure>
							<h4 class="feature-title">NFT Reward<br>Program</h4>
						</div>
						<div class="feature-box" data-aos="fade-up" data-aos-delay="0">
							<figure class="image">
								<img src="@/assets/img/feature-box/04.png">
							</figure>
							<h4 class="feature-title">Airdrop<br>to Holders</h4>
						</div>
						<div class="feature-box" data-aos="fade-up" data-aos-delay="250">
							<figure class="image">
								<img src="@/assets/img/feature-box/05.png">
							</figure>
							<h4 class="feature-title">Contests<br>and Games</h4>
						</div>
						<div class="feature-box" data-aos="fade-up" data-aos-delay="500">
							<figure class="image">
								<img src="@/assets/img/feature-box/06.png">
							</figure>
							<h4 class="feature-title">Gambling<br>Inferno</h4>
						</div>
					</div>
				</div>
			</article>
		</section>
		<section class="content-section rewards" data-section="rewards">
			<header class="section-header">
				<div class="content-wrap">
					<h3 class="section-label-title style-2" data-aos="fade-up">Reward Tier System</h3>
					<h2 class="section-title" data-aos="fade-up">Satan Rewards you Motherf*ckers</h2>
					<p data-aos="fade-up">We are proud and excited to present our high awaited program for our HELLISH
						holders. You are an
						essential part of the project, that's why we imagined this program to thank you
						periodically.</p>
				</div>
			</header>
			<article>
				<div class="content-wrap">
					<div class="reward-boxes-container">
						<div class="reward-box bronze" data-aos="fade-up">
							<figure class="image">
								<img src="@/assets/img/reward-box/bronze.png">
							</figure>
							<div class="text">
								<h4 class="reward-title">Bronze<br>Tier</h4>
								<p><strong class="highlight">1,666 HELL weekly</strong>
									<br><small>(up to <strong class="highlight">129% APR</strong>)</small></p>
								<p><small>Holder at least<br><span class="highlight">66,666 HELL</span></small></p>
							</div>
						</div>
						<div class="reward-box silver" data-aos="fade-up" data-aos-delay="250">
							<figure class="image">
								<img src="@/assets/img/reward-box/silver.png">
							</figure>
							<div class="text">
								<h4 class="reward-title">Silver<br>Tier</h4>
								<p><strong class="highlight">6,666 HELL weekly</strong>
									<br><small>(up to <strong class="highlight">104% APY</strong>)</small></p>
								<p><small>Holder at least<br><span class="highlight">333,333 HELL</span></small></p>
							</div>
						</div>
						<div class="reward-box gold" data-aos="fade-up" data-aos-delay="500">
							<figure class="image">
								<img src="@/assets/img/reward-box/gold.png">
							</figure>
							<div class="text">
								<h4 class="reward-title">Gold<br>Tier</h4>
								<p><strong class="highlight">16,666 HELL weekly</strong>
									<br><small>(up to <strong class="highlight">129% APY</strong>)</small></p>
								<p><small>Holder at least<br><span class="highlight">666,666 HELL</span></small></p>
							</div>
						</div>
						<div class="reward-box platinum" data-aos="fade-up" data-aos-delay="750">
							<figure class="image">
								<img src="@/assets/img/reward-box/platinum.png">
							</figure>
							<div class="text">
								<h4 class="reward-title">Platinum<br>Tier</h4>
								<p><strong class="highlight">36,666 HELL weekly</strong>
									<br><small>(up to <strong class="highlight">208% APR</strong>)</small></p>
								<p><small>Holder at least<br><span class="highlight">6,666,666 HELL</span></small></p>
							</div>
						</div>
					</div>
				</div>
			</article>
		</section>
		<section class="content-section nft" data-section="nft">
			<article>
				<div class="content-wrap">
					<div class="nft-info-box">
						<figure class="image" data-aos="fade-right">
							<img src="@/assets/img/nft-info-box/image.png">
						</figure>
						<div class="text" data-aos="fade-left">
							<h3 class="section-label-title style-1">Upcoming Inferno NFT</h3>
							<h2 class="section-title">Die poor or get rich in hell</h2>
							<p>INFERNO is a smart contract that allows users to play in INFERNO platform with their HELL
								tokens.</p>
							<div class="actions">
								<a href="#" class="button style-1">
									<span class="inner">About NFTS</span>
								</a>
								<a href="#" class="button style-2">
									<span class="inner">Mint Soon</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</article>
		</section>
		<section class="content-section game" data-section="game">
			<article>
				<div class="content-wrap">
					<div class="game-info-box">
						<h3 class="section-label-title style-2" data-aos="fade-up">Let's Kill Creatures</h3>
						<h2 class="section-title" data-aos="fade-up">Inferno Game awaits you!</h2>
						<p data-aos="fade-up">We are proud and excited to present our high awaited program for our
							HELLISH holders. You are
							an essential part of the project, that's why we imagined this program to thank you
							periodically.</p>
						<figure class="image" data-aos="fade">
							<img src="@/assets/img/game-info-box/laptops.png">
						</figure>
					</div>
				</div>
			</article>
		</section>
		<section class="content-section roadmap">
			<header class="section-header">
				<div class="content-wrap">
					<h3 class="section-label-title style-1" data-aos="fade-up">Hellish Roadmap</h3>
					<h2 class="section-title" data-aos="fade-up">Satan Rewards you Motherf*ckers</h2>
					<p data-aos="fade-up">We are proud and excited to present our high awaited program for our HELLISH
						holders. You are an
						essential part of the project, that's why we imagined this program to thank you
						periodically.</p>
				</div>
			</header>
			<article>
				<div class="content-wrap">
					<div class="roadmap-box">
						<div class="timeline">
							<div class="start">
								<div class="inner"></div>
							</div>
							<div class="line">
								<div class="inner"></div>
							</div>
							<div class="roadmap-quarter-box q1">
								<p class="quarter">Q1 2022</p>
								<div class="text" data-aos="fade-right">
									<h4 class="roadmap-pre-title">Hellish Foundation</h4>
									<h3 class="roadmap-title">The Birth of Hell</h3>
									<p>The birth of an idea and the realization of a hellish token. The full beginning
										of real
										evil.</p>
									<ul>
										<li>HELLISH Branding</li>
										<li>Tokenomics and details published</li>
										<li>Website and token release</li>
										<li>Reward Staking program</li>
									</ul>
								</div>
								<figure class="image" data-aos="fade-left">
									<img src="@/assets/img/roadmap-box/q1.png">
								</figure>
							</div>
							<div class="roadmap-quarter-box q2">
								<p class="quarter">Q2 2022</p>
								<div class="text" data-aos="fade-left">
									<h4 class="roadmap-pre-title">Development</h4>
									<h3 class="roadmap-title">Gain your monster</h3>
									<p>Listing on important platforms. The most important focus on the development of
										Inferno.</p>
									<ul>
										<li>Inferno Game Branding & Development</li>
										<li>Listed on DEX</li>
										<li>Listed on CoinMarketCap</li>
										<li>Listed on CoinGecko</li>
										<li>NFT Minting</li>
									</ul>
								</div>
								<figure class="image" data-aos="fade-right">
									<img src="@/assets/img/roadmap-box/q2.png">
								</figure>
							</div>
							<div class="roadmap-quarter-box q3">
								<p class="quarter">Q3 2022</p>
								<div class="text" data-aos="fade-right">
									<h4 class="roadmap-pre-title">Inferno Game</h4>
									<h3 class="roadmap-title">Fight and Earn</h3>
									<p>The most important part of the Hellish token route. Gradual development of a
										secret
										project.</p>
									<ul>
										<li>Inferno game Launch (maybe early)</li>
										<li>Secret project Development</li>
										<li>Big Partnerships</li>
										<li>Massive Buyback</li>
									</ul>
								</div>
								<figure class="image" data-aos="fade-left">
									<img src="@/assets/img/roadmap-box/q3.png">
								</figure>
							</div>
							<div class="end"></div>
						</div>
						<div class="roadmap-quarter-box q4" data-aos="fade-up">
							<p class="quarter">Q4 2022 / 2023</p>
							<div class="text">
								<h4 class="roadmap-pre-title">Releasing Soon</h4>
								<p>Everything depends on how many of you will burn in hell with us. We are curious how
									many
									people will be interested in this evil project having fun killing creatures and earn
									token for it.</p>
							</div>
						</div>
						<div class="actions" data-aos="fade-up">
							<a href="#" class="button style-1">
								<span class="inner">Buy Hellish</span>
							</a>
							<a href="#" class="button style-2">
								<span class="inner">Reward Program</span>
							</a>
						</div>
					</div>
				</div>
			</article>
		</section>
		<section class="content-section partners">
			<header class="section-header">
				<div class="content-wrap">
					<h3 class="section-label-title style-2" data-aos="fade-up">Partners</h3>
					<h2 class="section-title" data-aos="fade-up">These are not our partners, but they could be</h2>
					<p data-aos="fade-up">If you want to participate with us in the project or support us in any way,
						let us know. You may
						find yourself here.</p>
				</div>
			</header>
			<article>
				<div class="content-wrap">
					<div class="partners-boxes-container">
						<figure class="partner-box" data-aos="fade-up">
							<img src="@/assets/img/partner-box/01.png">
						</figure>
						<figure class="partner-box" data-aos="fade-up" data-aos-delay="150">
							<img src="@/assets/img/partner-box/02.png">
						</figure>
						<figure class="partner-box" data-aos="fade-up" data-aos-delay="300">
							<img src="@/assets/img/partner-box/03.png">
						</figure>
						<figure class="partner-box" data-aos="fade-up" data-aos-delay="450">
							<img src="@/assets/img/partner-box/04.png">
						</figure>
						<figure class="partner-box" data-aos="fade-up" data-aos-delay="600">
							<img src="@/assets/img/partner-box/05.png">
						</figure>
						<figure class="partner-box" data-aos="fade-up" data-aos-delay="750">
							<img src="@/assets/img/partner-box/06.png">
						</figure>
					</div>
				</div>
			</article>
		</section>
		<nav class="fixed-sections-nav">
			<ol class="sections-list">
				<li>
					<a data-target-section="intro" href="#" class="previous" @click.prevent="scrollToSection">Intro</a>
				</li>
				<li>
					<a data-target-section="features" href="#" @click.prevent="scrollToSection">features</a>
				</li>
				<li>
					<a data-target-section="rewards" href="#" @click.prevent="scrollToSection">Reward Tiers</a>
				</li>
				<li>
					<a data-target-section="nft" href="#" @click.prevent="scrollToSection">Inferno NFTS</a>
				</li>
				<li>
					<a data-target-section="game" href="#" @click.prevent="scrollToSection">Inferno Game</a>
				</li>
			</ol>
		</nav>
		<div class="fixed-message-container">
			<div class="message-container">
				<div class="message-inner">
					<p class="message">Die Bitch and burn in Hell</p>
				</div>
			</div>
		</div>
		<nav class="fixed-socials-nav" ref="fixedSocialsNav">
			<ul class="social-items-list">
				<li>
					<a href="#" class="social-item-link"><i class="fa-brands fa-twitter"></i></a>
				</li>
				<li>
					<a href="#" class="social-item-link"><i class="fa-solid fa-paper-plane"></i></a>
				</li>
				<li>
					<a href="#" class="social-item-link"><i class="fa-brands fa-discord"></i></a>
				</li>
			</ul>
		</nav>
		<div class="fixed-info-text">
			<p ref="infoTextParagraph">
				<span class="top-text">{{ infoText.top }}</span>
				<small class="bottom-text">{{ infoText.bottom }}</small>
			</p>
		</div>
	</main>
</template>

<script>
	import AOS from 'aos';

	export default {
		name: "HomeView",
		data() {
			return {
				infoTexts: [
					{
						top: '132+',
						bottom: 'Hodlers'
					},
					{
						top: '150',
						bottom: 'Test'
					},
					{
						top: '250+',
						bottom: 'Test 2'
					}
				],
				currentInfoText: 0,
			}
		},
		watch:{
			infoText(){
				setTimeout(() => {
					this.$refs.infoTextParagraph.classList.remove('hide');
				}, 400);

			}
		},
		computed: {
			infoText(){
				return this.infoTexts[this.currentInfoText];
			}
		},
		methods: {
			processInfoTexts(){
				setTimeout(() => {
					this.$refs.infoTextParagraph.classList.add('hide');

					setTimeout(() => {
						this.currentInfoText++;
						if (this.currentInfoText === this.infoTexts.length){
							this.currentInfoText = 0;
						}
					}, 500);


					this.processInfoTexts();
				}, 5000);
			},
			scrollToSection(event) {
				let targetSection = event.target.dataset.targetSection;
				if (!targetSection) {
					return false;
				}

				let section = document.querySelector(`*[data-section="${targetSection}"]`);
				if (!section) {
					return false;
				}

				section.scrollIntoView({
					behavior: 'smooth'
				});
			},
			processScrollEvents() {
				let sections = document.querySelectorAll('*[data-section]');
				if (!sections.length) {
					return false;
				}
				sections = Array.from(sections);

				window.addEventListener('scroll', (e) => {
					if (window.innerWidth < 992) {
						return false;
					}
					sections.forEach(section => {
						let navElement = document.querySelector(`.sections-list a[data-target-section="${section.dataset.section}"]`);
						let position = section.getBoundingClientRect();
						if (position.y <= (section.offsetHeight * -1)) {
							navElement.classList.remove('active');
							navElement.classList.add('previous');
						} else if (position.y <= 0) {
							navElement.classList.add('active');
							navElement.classList.remove('previous');
						} else {
							navElement.classList.remove('active');
							navElement.classList.remove('previous');
						}
					});
				})
			}
		},
		mounted() {
			setTimeout(() => {
				AOS.init();
			}, 3000);

			this.processScrollEvents();
			if (window.innerWidth > 991){
				this.processInfoTexts();
			}
		}
	}
</script>

<style scoped lang="scss">
	.fixed-info-text {
		position: fixed;
		left: 40px;
		bottom: 30px;
		@media($tabletL){
			display: none;
		}
		p {
			transition: opacity .5s;
			opacity: 1;
			&.hide {
				opacity: 0;
			}
			.top-text {
				color: $whiteColor;
				font-size: 36px;
				font-family: $secondaryFont;
				display: block;
				line-height: 1;
				margin-bottom: 5px;
			}
			.bottom-text {
				font-size: 16px;
				color: $primaryColor;
				text-transform: uppercase;
				font-family: $secondaryFont;
				display: block;
				line-height: 1;
				letter-spacing: 0.01em;
				margin-left: 2px;
			}
		}
	}

	.fixed-message-container {
		position: fixed;
		right: 30px;
		top: 50%;
		transform: translateY(-50%);

		@media(max-height: 825px){
			top: 45%;
		}

		.message-container {
			display: table;
			position: relative;
			right: calc(-100% + 32px);
		}
		.message-inner {
			height: 0;
			padding: 50% 0;
		}

		.message {
			//position: absolute;
			margin-top: -50%;
			text-transform: uppercase;
			//transform-origin: 100% 100%;
			transform-origin: top left;
			font-size: 14px;
			opacity: .2;
			color: $whiteColor;
			letter-spacing: .6em;
			transform: rotate(-90deg) translate(-100%);
		}
	}
	.fixed-socials-nav {
		position: fixed;
		right: 30px;
		bottom: 30px;
		transition: $animationDefaultDuration;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		z-index: 10;

		@media($tabletL) {
			display: none;
		}
	}

	.social-items-list {
		margin-top: auto;
		li + li {
			margin-top: 20px;
		}
	}

	.social-item-link {
		font-size: 16px;
		color: $whiteColor;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 100%;
		background: #1a1a1a;

		&:hover {
			background: $primaryColor;
		}
	}

	.fixed-sections-nav {
		position: fixed;
		left: 50px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;

		ol {
			list-style-type: none;
			counter-reset: sections-counter;

			li {
				position: relative;
				margin-bottom: 70px;

				&:after {
					content: '';
					display: block;
					position: absolute;
					left: 10px;
					top: calc(100% + 20px);
					height: 30px;
					width: 1px;
					background: transparentize($whiteColor, .9);
				}

				&:last-child {
					margin-bottom: 0;

					&:after {
						content: none;
					}
				}
			}

			a {
				counter-increment: sections-counter;
				position: relative;
				font-size: 16px;
				text-transform: uppercase;
				font-family: $secondaryFont;
				color: transparentize($whiteColor, .8);
				padding-left: 30px;

				&:before {
					content: counter(sections-counter);
					display: flex;
					align-items: center;
					justify-content: center;
					width: 20px;
					height: 20px;
					border: 1px solid transparentize($whiteColor, .9);
					font-size: 8px;
					color: transparentize($whiteColor, .8);
					position: absolute;
					left: 0;
					top: -1px;
					transition: $animationDefaultDuration;
					border-radius: 100%;
				}

				&.previous {
					color: $whiteColor;

					&:before {
						background: #1a1a1a;
						color: $whiteColor;
						border-color: #1a1a1a;
					}
				}

				&.active,
				&:hover {
					color: $primaryColor;

					&:before {
						border-color: $primaryColor;
						color: $primaryColor;
						background: transparent;
					}
				}
			}
		}

		@media($desktopL) {
			left: 30px;
		}
		@media($desktopM) {
			display: none;
		}
	}

	.hero-section {
		background-image: url("~@/assets/img/hero/background.png");
		background-size: 100% auto;
		background-repeat: no-repeat;
		text-align: center;
		//padding-top: 350px;
		padding-top: 18.25%;
		padding-bottom: 100px;
		position: relative;

		p {
			max-width: 525px;
			margin: 0 auto 50px;
			font-size: 18px;
		}

		.text-box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.hero-title {
			//font-size: 140px;
			font-size: 7.3vw;
			line-height: .92;
			margin-bottom: 50px;
			overflow: hidden;
			@media(min-width: 1921px) {
				font-size: 140px;
			}

			.line {
				position: relative;
				display: inline-block;
				//overflow: hidden;
				.text {
					opacity: 0;
					transition-delay: .75s;
				}

				&:before {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: -10px;
					bottom: -10px;
					width: 300%;
					background: $whiteColor;
					transform: translateX(calc(1px - 2px - 110%));
					transition: transform 3s;
				}

				&:nth-of-type(2) {
					.text {
						transition-delay: 1.25s;
					}

					&:before {
						transition-delay: 0.5s;
					}
				}
			}

			&.aos-animate {
				.line {
					.text {
						opacity: 1;
					}

					&:before {
						transform: translateX(calc(100% + 1px));
					}
				}
			}
		}

		.text-box {
			margin-bottom: 50px;
			@media(min-width: 1921px){
				margin-bottom: 100px;
			}
		}


		.scroll-down-box {
			p {
				font-size: 10px;
				margin-bottom: 15px;
				text-transform: uppercase;
				color: $whiteColor;
			}

			.scroll-down {
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 auto;
				background: $primaryColor;
				color: $whiteColor;
				border-radius: 100%;
				font-size: 10px;

				i {
					pointer-events: none;
				}

				&:hover {
					color: $primaryColor;
					background: $whiteColor;
				}
			}
		}

		@media($tabletL) {
			margin-top: 75px;
			p {
				font-size: 16px;
			}
			.message {
				display: none;
			}
		}
		@media($tabletM) {
			background-size: 900px auto;
			background-position-x: center;
			padding-top: 140px;
			padding-bottom: 50px;
			.hero-title {
				font-size: 56px;
			}
		}
		@media($mobileM) {
			padding-bottom: 25px;
			.hero-title {
				font-size: 40px;
				margin-bottom: 25px;
			}
			p {
				font-size: 14px;
			}
			.text-box {
				margin-bottom: 50px;
			}
		}
		@media($mobileS) {
			.hero-title {
				font-size: 37px;
				margin-bottom: 25px;
			}
		}
	}

	.content-section {
		padding: 150px 0;

		&.roadmap,
		&.partners {
			background: $blackColor;
		}
		&.nft {
			overflow: hidden;
		}

		&.roadmap {
			position: relative;
			&:before {
				content: '';
				left: 0;
				right: 0;
				bottom: 100%;
				height: 5%;
				background: linear-gradient(to top, $blackColor 0%, transparentize($blackColor, .99) 100%);
				position: absolute;
				display: block;
			}
		}

		&.features {
			.section-header p {
				max-width: 480px;
			}
		}

		&.rewards {
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: 0px;
				bottom: 0;
				background: url("~@/assets/img/rewards-section/hand.png") no-repeat top center;
				z-index: -1;
				opacity: .2;
			}

			@media($tabletL) {
				&:before {
					background-size: 500px;
				}
			}
			@media($tabletS) {
				&:before {
					background-size: 400px;
				}
			}
			@media($mobileM) {
				&:before {
					background-size: 350px;
				}
			}
			@media($mobileS) {
				&:before {
					background-size: 300px;
				}
			}

		}

		&.partners {
			position: relative;
			padding-bottom: 100px;

			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				max-width: 1300px;
				margin: 0 auto;
				background: $whiteColor;
				height: 1px;
				opacity: .1;
				@media($tabletM) {
					display: none;
				}
			}


			.section-header {
				.section-title {
					max-width: 720px;
				}
			}

			@media($tabletM) {
				padding: 50px 0;
			}
			@media($mobileM) {
				padding: 25px 0;
			}
		}

		@media($tabletM) {
			padding: 75px 0;
		}
		@media($mobileM) {
			padding: 50px 0;
		}
	}

	.feature-boxes-container {
		display: flex;
		flex-wrap: wrap;
		max-width: 980px;
		margin: 0 auto;

		.feature-box {
			width: 33.333%;

			&:nth-child(n + 4) {
				margin-top: 75px;
			}
		}

		@media($tabletL) {
			max-width: 750px;
		}
		@media($tabletM) {
			max-width: 450px;
			justify-content: space-between;
			.feature-box {
				width: 50%;

				&:nth-child(n + 3) {
					margin-top: 35px;
				}
			}
		}
	}

	.feature-box {
		display: flex;
		align-items: center;

		.image {
			min-width: 90px;
			margin-right: 25px;
		}

		.feature-title {
			font-size: 24px;
			text-transform: uppercase;
			margin: 0;
		}

		@media($tabletL) {
			.feature-title {
				font-size: 21px;
			}
			.image {
				width: 75px;
				min-width: 75px;
			}
		}
		@media($mobileM) {
			.feature-title {
				font-size: 16px;
			}
			.image {
				width: 60px;
				min-width: 60px;
				margin-right: 15px;
			}
		}
		@media($mobileS) {
			.feature-title {
				font-size: 14px;
			}
			.image {
				width: 55px;
				min-width: 55px;
			}
		}
	}

	.reward-boxes-container {
		display: flex;
		justify-content: space-between;
		@media($tabletL) {
			max-width: 550px;
			margin: 0 auto;
			flex-wrap: wrap;
			.reward-box {
				width: 48%;
			}
		}
		@media($mobileM) {
			.reward-box {
				width: 100%;
			}
		}
	}

	.reward-box {
		display: flex;

		&.bronze .reward-title {
			color: #c79872;
		}

		&.silver .reward-title {
			color: #a7acae;
		}

		&.gold .reward-title {
			color: #e6b83d;
		}

		&.platinum .reward-title {
			color: #af9e88;
			background: linear-gradient(145deg, rgba(119, 131, 137, 1) 0%, rgba(137, 143, 145, 1) 25%, rgba(152, 150, 146, 1) 37%, rgba(170, 158, 139, 1) 50%, rgba(180, 157, 128, 1) 56%, rgba(177, 151, 114, 1) 62%, rgba(194, 152, 107, 1) 68%, rgba(233, 163, 88, 1) 75%, rgba(248, 232, 114, 1) 81%, rgba(241, 204, 64, 1) 87%, rgba(185, 115, 61, 1) 94%, rgba(111, 77, 62, 1) 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.image {
			min-width: 118px;
			margin-right: 10px;
		}

		.text {
			margin-top: 20px;
		}

		.reward-title {
			font-size: 30px;
			line-height: 1;
			text-transform: uppercase;
			margin-bottom: 15px;
		}

		p {
			font-size: 14px;
			margin-bottom: 10px;
			padding-bottom: 10px;
			position: relative;

			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				height: 1px;
				width: 90px;
				background: $whiteColor;
				opacity: .1;
			}

			&:last-child {
				padding-bottom: 0;
				margin-bottom: 0;

				&:after {
					content: none;
				}
			}

			.highlight {
				color: $whiteColor;
			}

			small {
				font-size: 12px;
			}
		}

		@media($desktopM) {
			.image {
				min-width: 100px;
				width: 100px;
			}
			.text {
				margin-top: 0;
			}
			.reward-title {
				font-size: 26px;
				margin-bottom: 10px;
			}
		}
		@media($mobileM) {
			.reward-title {
				font-size: 30px;
			}
			.text {
				margin-top: 25px;
			}
			.image {
				min-width: 118px;
				width: 118px;
			}

		}
	}

	.nft-info-box {
		display: flex;
		align-items: center;

		.text {
			max-width: 410px;
			margin-left: 100px;
			margin-bottom: 75px;
		}

		.actions {
			margin-top: 45px;
			display: flex;

			.button {
				margin-right: 20px;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		@media($tabletL) {
			flex-direction: column-reverse;
			align-items: center;
			.text {
				margin-left: 0;
				text-align: center;
			}
			.actions {
				text-align: center;
				justify-content: center;
			}
			.image {
				margin-top: 25px;
				max-width: 500px;
			}
		}
		@media($mobileM) {
			.image {
				margin-top: 0;
			}
		}

	}

	.game-info-box {
		text-align: center;
		background: url("~@/assets/img/game-info-box/background.png") no-repeat top center;
		background-size: contain;
		padding: 100px 50px;

		p {
			max-width: 675px;
			margin: 0 auto 50px;
		}

		.image {
			margin-left: -50px;
			margin-right: -50px;
		}

		@media($tabletM) {
			background-size: auto 100%;
			margin: 0 -15px;
			.image {
				margin-left: -35px;
				margin-right: -35px;
			}
		}
		@media($mobileM) {
			padding: 75px 35px;
			.section-title {
				max-width: 200px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.roadmap-box {
		position: relative;

		@media($tabletM) {
			max-width: 500px;
			margin: 0 auto;
		}

		.roadmap-quarter-box {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 50px;

			&:last-child {
				margin-bottom: 0;
			}

			.quarter {
				width: 100%;
				margin-bottom: 25px;
				text-align: center;
				font-size: 24px;
				color: $whiteColor;
				font-family: $secondaryFont;
				background: $blackColor;
				padding: 15px;
				z-index: 3;
			}

			.text {
				width: calc(50% - 100px);
				text-align: right;
				margin-right: 100px;
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				p {
					opacity: .5;
					max-width: 350px;
					font-size: 14px;
				}
			}

			.roadmap-pre-title {
				color: $primaryColor;
				letter-spacing: .1em;
				font-size: 16px;
				margin-bottom: 10px;
			}

			.roadmap-title {
				font-size: 36px;
				margin-bottom: 20px;
			}

			.image {
				width: calc(50% - 100px);
				margin-left: 100px;
			}

			ul {
				font-size: 14px;

				li {
					padding-right: 15px;
					position: relative;

					&:before {
						content: '';
						display: block;
						position: absolute;
						right: 0;
						top: 11px;
						height: 4px;
						width: 4px;
						background: $fontColor;
						border-radius: 100%;
					}
				}
			}

			@media(min-width: 769px) {
				&.q2 {
					flex-direction: row-reverse;

					.text {
						text-align: left;
						align-items: flex-start;
						margin-right: 0;
						margin-left: 100px;
					}

					.image {
						margin-left: 0;
						margin-right: 100px;
						text-align: right;
					}

					ul {
						li {
							padding-right: 0;
							padding-left: 15px;

							&:before {
								right: auto;
								left: 0;
							}
						}
					}

					@media($tabletL) {
						.text {
							margin-left: 50px;
						}
						.image {
							margin-right: 50px;
						}
					}
				}
			}

			&.q4 {
				.roadmap-pre-title {
					margin-bottom: 20px;
				}

				.text {
					text-align: center;
					align-items: center;
					margin: 0 auto;

					p {
						max-width: 475px;
					}
				}
			}

			@media($tabletL) {
				.text {
					width: calc(50% - 50px);
					margin-right: 50px;
				}
				.image {
					width: calc(50% - 50px);
					margin-left: 50px;
				}
			}
			@media($tabletM) {
				.text,
				.image {
					width: 100%;
					margin-right: 0;
					margin-left: 0;
					text-align: left;
					align-items: flex-start;
					max-width: calc(100% - 90px);
				}
				&.q4 {
					.text,
					.image {
						max-width: 100%;
					}
				}
				ul {
					li {
						padding-right: 0;
						padding-left: 15px;

						&:before {
							right: auto;
							left: 0;
						}
					}
				}
				.image {
					margin-top: 25px;
				}
				.quarter {
					text-align: right;
				}
			}
			@media($mobileS) {
				.roadmap-title {
					font-size: 30px;
				}
			}
		}

		.actions {
			display: flex;
			justify-content: center;

			.button {
				margin-right: 20px;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.timeline {
			position: relative;
			margin-bottom: 10px;
		}

		.line {
			position: absolute;
			background: #2c2c2c;
			width: 2px;
			left: 0;
			right: 0;
			margin: 0 auto;
			top: 0;
			bottom: 20px;
			z-index: 1;

			.inner {
				position: absolute;
				display: block;
				left: 0;
				right: 0;
				top: 0;
				height: 20%;
				background: $primaryColor;
			}

			@media($tabletM) {
				margin-right: 0;
				right: 40px;
			}
		}

		.start {
			position: relative;
			width: 15px;
			height: 15px;
			background: $blackColor;
			border-radius: 100%;
			border: 4px solid $primaryColor;
			margin: 0 auto 50px;
			z-index: 2;

			.inner,
			&:before,
			&:after {
				position: absolute;
				display: block;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				border: 1px solid white;
				border-radius: 100%;
				animation: animate-border 3s linear infinite;
			}

			&:before,
			&:after {
				content: '';
			}

			&:after {
				animation-delay: 2s;
			}

			.inner {
				animation-delay: 1s;
			}

			@media($tabletM) {
				margin-right: 0;
				right: 33px;
			}
		}

		.end {
			width: 10px;
			height: 10px;
			background: #2c2c2c;
			border-radius: 100%;
			margin: 0 auto;
			@media($tabletM) {
				margin-right: 0;
				right: 36px;
				position: relative;
			}
		}
	}

	@keyframes animate-border {
		0% {
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			opacity: .2;
		}
		100% {
			left: -50px;
			right: -50px;
			top: -50px;
			bottom: -50px;
			opacity: .05;
		}
	}

	.partners-boxes-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1220px;
		margin: 0 auto;
		@media($tabletL) {
			flex-wrap: wrap;
			.partner-box {
				width: 33.333%;
				text-align: center;
				padding: 0 10px;

				&:nth-child(n + 4) {
					margin-top: 35px;
				}
			}
		}
		@media($mobileM) {
			.partner-box {
				width: 50%;

				&:nth-child(n + 3) {
					margin-top: 35px;
				}
			}
		}
	}

</style>