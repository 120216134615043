<template>
	<footer class="page-footer">
		<div class="content-wrap">
			<ul class="social-items-list">
				<li>
					<a href="#" class="social-item-link"><i class="fa-brands fa-twitter"></i></a>
				</li>
				<li>
					<a href="#" class="social-item-link"><i class="fa-solid fa-paper-plane"></i></a>
				</li>
				<li>
					<a href="#" class="social-item-link"><i class="fa-brands fa-discord"></i></a>
				</li>
			</ul>
			<ul class="pages-list">
				<li><a href="#">Solscan</a></li>
				<li><a href="#">Nomics</a></li>
				<li><a href="#">CoinMarketCap</a></li>
				<li><a href="#">CoinGecko</a></li>
				<li><a href="#">Discord</a></li>
				<li><a href="#">Tokenomics</a></li>
				<li><a href="#">Reward Program</a></li>
			</ul>
			<p class="credits">© 2022 HELLISH Token. All Right Reseved</p>
			<figure class="logo">
				<img src="@/assets/img/logo.png">
			</figure>
		</div>
	</footer>
</template>

<script>
	export default {
		name: "PageFooter"
	}
</script>

<style scoped lang="scss">
	.page-footer {
		padding-top: 125px;
		padding-bottom: 200px;
		background: url("~@/assets/img/footer/background.jpg") no-repeat center bottom;

		@media(min-width: 1921px){
			background-size: 100% auto;
		}
		@media($tabletL){
			padding-top: 75px;
			padding-bottom: 100px;
			background-size: 1200px auto;
		}
		@media($tabletM){
			padding-top: 50px;
			padding-bottom: 50px;
		}
	}

	.social-items-list {
		display: flex;
		justify-content: center;
		margin-bottom: 35px;

		li + li {
			margin-left: 20px;
		}
	}

	.social-item-link {
		font-size: 20px;
		color: $whiteColor;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		border-radius: 100%;
		background: #1a1a1a;

		&:hover {
			background: $primaryColor;
		}
	}

	.pages-list {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;

		li + li {
			margin-left: 20px;
		}

		a {
			color: $whiteColor;
			opacity: .5;

			&:hover {
				opacity: 1;
			}
		}
		@media($tabletL){
			font-size: 14px;
		}
		@media($tabletM){
			flex-direction: column;
			li {
				text-align: center;
			}
			li + li {
				margin-left: 0;
				margin-top: 20px;
			}
		}
	}

	.credits {
		font-size: 14px;
		opacity: .2;
		text-align: center;
		margin-bottom: 50px;
		color: $whiteColor;
	}

	.logo {
		text-align: center;

	}
</style>