import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {},
	state: {},
	mutations: {},
	actions: {},

});

export default store;