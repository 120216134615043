import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "../store";
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'HomeView',
		component: HomeView,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return {x: 0, y: 0};
	}
});

export default router;
