<template>
	<header class="page-header">
		<div class="content-wrap wide">
			<div class="container">
				<div class="left">
					<figure class="logo-box">
						<a href="/">
							<img src="@/assets/img/logo.png">
						</a>
					</figure>
					<!--<a href="#" class="menu-toggle">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</a>-->
				</div>
				<div class="right">
					<a href="#" class="button style-1">
						<span class="inner">Buy Hellish</span>
					</a>
					<a href="#" class="button style-2">
						<span class="inner">Reward Program</span>
					</a>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	export default {
		name: "PageHeader"
	}
</script>

<style scoped lang="scss">
	.page-header {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		padding: 15px 0;
		z-index: 2;
		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.right {
		display: flex;
		.button {
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.logo-box {
		position: relative;
		left: -20px;
		a {
			&:hover {
				opacity: .75;
			}
		}
	}
	@media($tabletL){
		.page-header {
			padding: 25px 0;
			&:before {
				content: '';
				left: 0;
				right: 0;
				top: 0;
				height: calc(100% + 40px);
				display: block;
				position: absolute;
				background: linear-gradient(to bottom,  $blackColor 0%, $blackColor 50%, transparentize($blackColor, .99)  100% );
				pointer-events: none;
			}
		}
		.left {
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			text-align: center;
			top: 0;

		}
		.right {
			justify-content: space-between;
			display: flex;
			width: 100%;
		}
		.logo-box {
			left: 0;
			max-width: 100px;
			margin: 0 auto;
			top: 5px;
		}
	}
	@media($mobileS){
		.logo-box {
			max-width: 75px;
			left: -5px;
			top: 15px;
		}
	}
</style>