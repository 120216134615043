<template>
	<div id="app">
		<page-header/>
		<router-view/>
		<page-footer/>
		<div id="particles-js"></div>
	</div>
</template>

<script>
	import PageHeader from "./components/partials/PageHeader";
	import PageFooter from "./components/partials/PageFooter";


	export default {
		name: "App",
		components: {
			PageHeader,
			PageFooter
		},
		methods: {
			updateParticles(){
				requestAnimationFrame(this.updateParticles);
			}
		},
		mounted() {
			setTimeout(() => {
				document.body.classList.remove('loader');
			}, 2750);
			if (window.innerWidth > 991) {
				particlesJS("particles-js", {
					"particles": {
						"number": {"value": 35, "density": {"enable": true, "value_area": 800}},
						"color": {"value": ["#fc3e3b", "#ffffff", "#fc3e3b"]},
						"shape": {
							"type": "circle",
							"stroke": {"width": 0, "color": "#000000"},
							"polygon": {"nb_sides": 5},
							"image": {"src": "img/github.svg", "width": 100, "height": 100}
						},
						"opacity": {"value": 1, "random": true, "anim": {"enable": true, "speed": 1, "opacity_min": 0, "sync": false}},
						"size": {"value": 1, "random": true, "anim": {"enable": false, "speed": 4, "size_min": 0.3, "sync": false}},
						"line_linked": {"enable": false, "distance": 150, "color": "#ffffff", "opacity": 0.4, "width": 1},
						"move": {
							"enable": true,
							"speed": 1,
							"direction": "none",
							"random": true,
							"straight": false,
							"out_mode": "out",
							"bounce": false,
							"attract": {"enable": false, "rotateX": 600, "rotateY": 600}
						}
					},
					"retina_detect": true
				});

				requestAnimationFrame(this.updateParticles);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/scss/screen";

</style>